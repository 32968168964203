import request from '@/utils/request.js'

export function loginAd(data) {
  return request({
    url: 'login',
    method: 'post',
    data
  })
}

export function forgetPs(data) {
  return request({
    url: 'forget',
    method: 'post',
    data
  })
}

export function sendSms(data) {
  return request({
    url: 'sendMsg',
    method: 'post',
    data
  })
}

//数据预览
export function getInfoData(data){
	return request({
	  url: 'config/info',
	  method: 'get',
	  params:data
	})
}

export function addCount(data) {
  return request({
    url: 'config/add',
    method: 'post',
    data
  })
}

export function getLineData(data){
	return request({
	  url: 'lineData',
	  method: 'get',
	  params:data
	})
}

//上传图片
export function uploadImg(data) {
  return request({
    url: 'uploadImg',
    method: 'post',
    data,
	headers:{
    // 表示上传的是文件,而不是普通的表单数据
       'Content-Type': 'multipart/form-data'
    }
  })
}

//设置
export function getConfig(data) {
  return request({
    url: 'config/info',
    method: 'get',
    params:data
  })
}
export function saveConfig(data) {
  return request({
    url: 'config/save',
    method: 'post',
    data
  })
}

export function getCate(data) {
  return request({
    url: 'cate',
    method: 'get',
    params:data
  })
}

export function getPay(data) {
  return request({
    url: 'pay',
    method: 'get',
    params:data
  })
}