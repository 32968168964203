const getters = {
  visitedViews: state => state.tags.visitedViews,
  sidebar: state => state.setting.sidebar,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  title: state => state.setting.title,
  logo: state => state.setting.logo
}
export default getters
