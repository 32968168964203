import Cookies from 'js-cookie'

const state = {
  sidebar: {
    opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  title: '后台管理',//Cookies.get('app_name')?Cookies.get('app_name'):
  logo: '../../assets/logo.jpg'//'https://wpimg.wallstcn.com/69a1c46c-eb1c-4b46-8bd4-e9e686ef5251.png'//Cookies.get('app_logo')?Cookies.get('app_logo'):
}

const mutations = {
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1)
    } else {
      Cookies.set('sidebarStatus', 0)
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    Cookies.set('sidebarStatus', 0)
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  SET_SYS_TITLE:(state,title)=>{
	  // Cookies.set('app_name', title)
	  // state.title = title
  },
  SET_SYS_LOGO:(state,logo)=>{
	  // Cookies.set('app_logo', logo)
	  // state.logo = logo
  }
}

const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  setSysTitle({commit},title){
	commit('SET_SYS_TITLE',title);
  },
  setSysLogo({commit},logo){
	commit('SET_SYS_LOGO',logo); 
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
