<template>
	<div class="common-layout">
	  <el-container>
		 <!-- <el-header>
		  	 <nav-bar></nav-bar>
		  </el-header> -->
		  <el-main>
		  	 <app-main></app-main>
		  </el-main>
		 <!-- <el-footer>
			 <footer-main></footer-main> 
		  </el-footer> -->
	  </el-container>
	</div>
</template>

<script>
	import {mapGetters} from 'vuex'
	// import navBar from './navBar/navBar.vue'
	import appMain from './main/appMain.vue'
	// import footerMain from './footer/footerMain.vue'
	export default{
	    components:{
			// navBar,
			appMain
		},
		computed: {
		    key() {
		        return this.$route.path
		    }
		}
	}
</script>

<style lang="scss" scoped>
	@import "~@/styles/mixin.scss";
	@import "~@/styles/variables.scss";
	.common-layout{
		width: 100%;
		height: 100%;
		.el-container{
			width: 100%;
			height: 100%;
		}
		// .el-header{
			// height: 85px !important;
		// 	box-shadow: 0 1px 4px rgba(0,21,41,.08);
		// }
		.el-main{
			padding: 0 !important;
		}
	}
</style>