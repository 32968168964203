import { createStore } from 'vuex'
import getters from './getters'
import user from './modules/user.js'
import setting from './modules/setting.js'
import tags from './modules/tags.js'

const store = createStore({
	modules:{
		user,
		setting,
		tags
	},
	getters	
})

export default store