<template>
	<router-view :key="key" />
</template>

<script>
	export default{
		name: 'appMain',
		computed: {
		  key() {
		    return this.$route.path
		  }
		}
	}
</script>

<style lang="scss">
	.app-main{
		width: 100%;
	}
	/* fade-transform */
	.fade-transform-leave-active,
	.fade-transform-enter-active {
	  transition: all .5s;
	}
	
	.fade-transform-enter {
	  opacity: 0;
	  transform: translateX(-30px);
	}
	
	.fade-transform-leave-to {
	  opacity: 0;
	  transform: translateX(30px);
	}
</style>