import router from '@/router'
// import store from '@/store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
// import { getToken } from '@/utils/auth' // get token from cookie


NProgress.configure({ showSpinner: false })

const whiteList = ['/']

router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start()
  // document.title = getPageTitle(to.meta.title)
  // const hasToken = getToken()
  // if (hasToken) {
  //   if (to.path === '/login') {
  //     // if is logged in, redirect to the home page
	 //  next({ path: '/' })
  //     // NProgress.done()
  //   } else {
	  next()
  // //     const hasGetUserInfo = store.getters.name
  // //     if (hasGetUserInfo) {
  // //       next()
		// // // console.log(to,from)
  // //     } else {
		// // next()
		// // NProgress.done()
  //   //     try {
  //   //       // get user info
  //   //       // await store.dispatch('user/getInfo')
  //   //       next({ ...to, replace: true })
  //   //     } catch (error) {
  //   //       // remove token and go to login page to re-login
  //   //       await store.dispatch('user/resetToken')
  //   //       // Message.error(error || 'Has Error')
  //   //       next('/login')
		//   // // ?redirect=${to.path}
  //   //       NProgress.done()
  //   //     }
  //     // }
  //   }
  // } else {
    /* has no token*/

   //  if (whiteList.indexOf(to.path) !== -1) {
   //    // in the free login whitelist, go directly
   //    next()
   //  } else {
   //    // other pages that do not have permission to access are redirected to the login page.
   // //    if(to.path === '/forget'){
		 // //  next()
	  // // }else{
		 //  next('/')
	  // // }
	  // // ?redirect=${to.path}`
   //    // NProgress.done()
   //  }
  // }
})


router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})