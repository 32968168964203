import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from '@/router'
import store from '@/store'
import 'normalize.css/normalize.css'
import '@/styles/index.scss'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import App from '@/App.vue'

import '@/permission'

const app = createApp(App)

app.provide('uploadUrl', 'https://fg12345.com/stage/uploadImg')

app.use(ElementPlus,{
	// size:'default',
	locale: zhCn
})
app.use(router)
app.use(store)
app.mount('#app')


