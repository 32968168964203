import {createRouter,createWebHashHistory} from 'vue-router';

import Layout from '@/layout/index';

const routes = [
	// {
	// 	path:'/login',
	// 	name:'login',
	// 	component: () => import('@/views/login/index'),
	// 	hidden:true
	// },
	{
	  path: '/',
	  component: Layout,
	  redirect: '/index',
	  children: [{
	    path: '/index',
	    name: 'dashboard',
	    component: () => import('@/views/index/index')
	    // meta: { title: '数据概览', icon: 'Odometer', affix: true }
	  }]
	},
	{
		path: '/goods',
		component: Layout,
		children: [
		  {
			path: '/goods:id',
			name: 'goods',
			component: () => import('@/views/goods/index'),
			hidden:true
		  }
		]
	},
	{
		path: '/pay',
		component: Layout,
		children: [
		  {
			path: '/pay:id',
			name: 'pay',
			component: () => import('@/views/pay/index'),
			hidden:true
		  }
		]
	}
];

const router = createRouter({
	history:createWebHashHistory(),
	routes
});

export function resetRouter() {
	
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router;
